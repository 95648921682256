import { createApp, defineAsyncComponent } from "vue";

import SvgVue from 'svg-vue3'
import VueLazyLoad from 'vue3-lazyload'

const app = createApp({
  components: {

    Slider: defineAsyncComponent(() => import('./common/Slider.vue')),
    SocialIcons: defineAsyncComponent(() => import('./common/SocialMediaIcons.vue')),
    ContactForm: defineAsyncComponent(() => import('./common/ContactForm.vue')),
    ReservationForm: defineAsyncComponent(() => import('./common/ReservationForm.vue')),
    Accommodations: defineAsyncComponent(() => import('./common/Accommodations.vue')),

    PhotoGallery: defineAsyncComponent(() => import('./blocks/PhotoGallery.vue')),
    AccordionList: defineAsyncComponent(() => import('./blocks/AccordionList.vue')),
    AccordionPanels: defineAsyncComponent(() => import('./blocks/AccordionPanels.vue')),
    TabBox: defineAsyncComponent(() => import('./blocks/TabBox.vue')),

  },

  data: () => ({
    openMenu: '',
    scrollPosition: 0,
    activeSideBarNav: '',
    alertsOpen: false,
    activeMobileNavGroup: 0
  }),

  computed: {
    mobileMenu () {
      return this.openMenu === 'mobileMenu'
    },
    desktopMenu () {
      return this.openMenu === 'dropdownMenu'
    },
    isScrolling () {
      return this.scrollPosition > 0
    },
  },

  mounted () {

    if (localStorage.popup === true || !('popup' in localStorage)) {
      this.popupOpen = true
    } else {
      this.popupOpen = false
    }

  },

  methods: {
    toggleMenu (menu) {
      this.openMenu = (this.openMenu != menu ? menu : '')
    },
    setMobileNavGroup (item) {
      this.activeMobileNavGroup = this.activeMobileNavGroup != item ? item : ''
    },
    toggleFaq(val){
      this.activeFaq = this.activeFaq == val ? '' : val
    },
    handleScroll (event) {
      this.scrollPosition = window.pageYOffset != undefined ? window.pageYOffset : window.scrollY
    },
    setSidebar (sidebar) {
      this.activeSideBarNav = sidebar
    },
    setNavItem(val) {
      this.activeSideBarNav = val
    },
  },


  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

})

app.use(VueLazyLoad)
app.use(SvgVue, {
  svgPath: 'resources/svg',
  extract: false,
  svgoSettings: [
      { removeTitle: true },
      { removeViewBox: false },
      { removeDimensions: true }
  ]
})

app.mount("#app")
